import React from 'react';

interface PagerProps {
  numberToShow: number;
  totalPages: number;
  totalItems: number;
  currentPage: number;
  ArrowIcon: React.ReactNode;
  onPageChange: (page: number) => void;
  changeNumberToShow?: (numberToShow: number) => void;
}

const Pager: React.FC<PagerProps> = ({ numberToShow, totalPages, totalItems, currentPage, ArrowIcon, onPageChange, changeNumberToShow }) => {

  const handlePageChange = (event: React.ChangeEvent<HTMLSelectElement> | React.MouseEvent<HTMLButtonElement>) => {
    let newPage = currentPage;
    if (event.target instanceof HTMLSelectElement) {
      newPage = Number(event.target.value);
    } else if (event.currentTarget instanceof HTMLButtonElement) {
      const direction = (event.currentTarget as HTMLButtonElement).getAttribute("data-direction");
      if (direction === "prev" && currentPage > 1) {
        newPage = currentPage - 1;
      } else if (direction === "next" && currentPage < totalPages) {
        newPage = currentPage + 1;
      }
    }
    onPageChange(newPage);
  };

  const handleNumberChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const numberToShow = Number(event.target.value);
    if (changeNumberToShow) {
      changeNumberToShow(numberToShow);
    }
  };

  const numberToShowElement = ()  => {
    return (
      totalItems > 5 &&
      <p className="flex gap-1 m-auto items-center justify-center">
        <span>Items to show:</span>
        <select
          className="border border-1 min-w-12 px-2 py-1 focus:ring-2 focus:ring-blue-500 focus:outline-none rounded"
          onChange={handleNumberChange}
          value={numberToShow}
        >
          <option key={1} value={5}>5</option>
          <option key={2} value={10}>10</option>
          <option key={3} value={15}>15</option>
          <option key={4} value={20}>20</option>
        </select>
      </p>
    );
  }

  if (totalPages <= 1) return (
    <div className="flex mt-4 gap-2 mx-auto">
      {numberToShowElement()}
    </div>
  );

  return (
    <div className="flex mt-12 gap-2 mx-2">
      <p className="flex gap-1 items-center justify-center">
        <span>Page</span>
        <select
          className="border border-1 min-w-12 px-2 py-1 focus:ring-2 focus:ring-blue-500 focus:outline-none rounded"
          onChange={handlePageChange}
          value={currentPage}
        >
          {Array.from({ length: totalPages }, (_, i) => (
            <option key={i} value={i + 1}>
              {i + 1}
            </option>
          ))}
        </select>
        <span>of {totalPages}.</span>
      </p>
      {numberToShowElement()}
      <div className="ml-auto flex items-center">
        <button
          className="focus:ring-2 focus:ring-blue-500 focus:outline-none h-8 w-8 rounded-full"
          data-direction="prev"
          onClick={handlePageChange}
          disabled={currentPage === 1}
        >
          {ArrowIcon}
        </button>
        <button
          className="focus:ring-2 focus:ring-blue-500 focus:outline-none rounded-full h-8 w-8 transform rotate-180"
          data-direction="next"
          onClick={handlePageChange}
          disabled={currentPage === totalPages}
        >
          {ArrowIcon}
        </button>
      </div>
    </div>
  );
};

export default Pager;