import React, { useState } from "react";
import { useId } from "react";
import { Label } from "@/components/ui/label";
import { CommonInput } from "@fields/Fields";
import RadioButtonRow from "@/components/radiobuttons/RadioButtonRow";
import {
  SurveyQuestionType,
  SurveyQuestionSchema,
  SurveyChoice,
} from "./Survey";
import ImagePicker from "@/components/pickers/imagePicker";
import { ChevronDown, ChevronUp } from "lucide-react";
import { Divider } from "@fields/Display";

const DEFAULT_IMAGE = import.meta.env.VITE_APP_DEFAULT_IMAGE;
const imgDelete = "/images/delete.png";
// const imgPlus = "/images/plus.png";

interface QuestionTypeProps {
  value: SurveyQuestionType;
  onChange: (value: SurveyQuestionType) => void;
}

export const SurveyQuestionTypeSelector: React.FC<QuestionTypeProps> = ({
  value,
  onChange,
}) => {
  const options: { label: string; value: SurveyQuestionType }[] = [
    { label: "Single", value: "single" },
    { label: "Multi", value: "multi" },
  ];

  return (
    <div className="flex flex-col justify-end w-full">
      <Label htmlFor="survey-question-type" className="text-sm mb-1.5">
        Question type
      </Label>
      <div className="h-9 flex items-center">
        <RadioButtonRow<SurveyQuestionType>
          name="survey-question-type"
          value={value}
          onChange={onChange}
          options={options}
        />
      </div>
    </div>
  );
};

interface InputProps {
  value: string;
  onChange: (value: string) => void;
  id?: string;
}

export const SurveyQuestionField: React.FC<InputProps> = ({
  value,
  onChange,
  id: providedId,
}) => {
  const generatedId = useId();
  const id = providedId ?? `survey-question-${generatedId}`;
  return (
    <CommonInput
      id={id}
      entity_name="Question"
      required
      helpText="Question for the Survey."
      value={value}
      onChange={(value) => onChange(value)}
    />
  );
};

export const SurveyResponseField: React.FC<InputProps> = ({
  value,
  onChange,
  id: providedId,
}) => {
  const generatedId = useId();
  const id = providedId ?? `survey-response-${generatedId}`;
  return (
    <CommonInput
      id={id}
      entity_name="Response"
      required
      helpText="Response for the Survey question."
      value={value}
      onChange={(value) => onChange(value)}
    />
  );
};

interface SurveyQuestionProps {
  questionNumber: number;
  question: SurveyQuestionSchema;
  onChange: (question: SurveyQuestionSchema) => void;
  onDelete?: () => void;
}

export const SurveyQuestion: React.FC<SurveyQuestionProps> = ({
  questionNumber,
  question,
  onChange,
  onDelete,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const imgPlus = "/images/plus.png";

  const handleAddChoice = () => {
    const newChoice: SurveyChoice = {
      id: `choice-${Date.now()}`,
      text: "",
      imgUrl: DEFAULT_IMAGE,
    };
    console.log("Adding new choice");
    onChange({
      ...question,
      choices: [...question.choices, newChoice],
    });
  };

  const handleDeleteChoice = (choiceId: string) => {
    onChange({
      ...question,
      choices: question.choices.filter((choice) => choice.id !== choiceId),
    });
  };

  const handleChoiceTextChange = (choiceId: string, text: string) => {
    onChange({
      ...question,
      choices: question.choices.map((choice) =>
        choice.id === choiceId ? { ...choice, text } : choice
      ),
    });
  };

  const handleChoiceImageChange = (choiceId: string /*, imgUrl: File*/) => {
    onChange({
      ...question,
      choices: question.choices.map((choice) =>
        choice.id === choiceId ? { ...choice /*, imgUrl */ } : choice
      ),
    });
  };

  const handleQuestionTextChange = (text: string) => {
    onChange({
      ...question,
      text,
    });
  };

  const handleQuestionImageChange = (/*imgUrl: File*/) => {
    onChange({
      ...question,
      //imgUrl,
    });
  };

  const handleTypeChange = (type: SurveyQuestionType) => {
    onChange({
      ...question,
      type,
    });
  };

  return (
    <div className="rounded-lg border border-gray-200 bg-white shadow-sm overflow-hidden">
      <button
        type="button"
        className="w-full px-4 py-3 text-left flex items-center justify-between bg-white hover:bg-gray-50"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <span className="font-medium">Question {questionNumber}</span>
        <div className="flex items-center gap-2">
          <div className="h-8 w-8 rounded-full bg-gray-100 flex items-center justify-center">
            {isExpanded ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
          </div>
        </div>
      </button>

      {isExpanded && (
        <div className="p-4 space-y-4 bg-white">
          <div className="flex justify-end gap-2 mb-2">
            <button
              id="btn_add_survey_response"
              type="button"
              className="p-1 hover:bg-gray-100 rounded"
              title="Add Response"
              onClick={handleAddChoice}
            >
              <img src={imgPlus} alt="Add" className="w-4 h-4" />
            </button>

            {onDelete && (
              <button
                id="btn_delete_survey_question"
                type="button"
                className="p-1 hover:bg-gray-100 rounded"
                title="Delete Question"
                onClick={onDelete}
              >
                <img
                  src={imgDelete}
                  alt="Delete"
                  className="w-4 h-4 cursor-pointer"
                />
              </button>
            )}
          </div>

          <SurveyQuestionTypeSelector
            value={question.type}
            onChange={handleTypeChange}
          />
          <div className="col-span-2 grid grid-cols-7 gap-4">
            <div className="col-span-6">
              <SurveyQuestionField
                value={question.text}
                onChange={handleQuestionTextChange}
              />
            </div>
            <div className="col-span-1 self-start mt-8 flex items-start">
              <div className="mr-2 ml-5">
                <ImagePicker
                  path={question.imgUrl}
                  onImagePicked={handleQuestionImageChange}
                  id={`img-${question.id}`}
                  size="tiny"
                />
              </div>
            </div>
          </div>
          <Divider />
          {question.choices.map((item) => (
            <div key={item.id}>
              <div className="col-span-2 grid grid-cols-7 gap-4">
                <div className="col-span-6">
                  <SurveyResponseField
                    value={item.text}
                    onChange={(value) => handleChoiceTextChange(item.id, value)}
                  />
                </div>
                <div className="col-span-1 self-start mt-8 flex items-start">
                  <div className="mr-5 ml-5">
                    <ImagePicker
                      path={item.imgUrl}
                      onImagePicked={
                        (/*file*/) =>
                          handleChoiceImageChange(item.id /*, file*/)
                      }
                      id={`img-${item.id}`}
                      size="tiny"
                    />
                  </div>
                  <img
                    src={imgDelete}
                    onClick={() => handleDeleteChoice(item.id)}
                    className="w-4 h-4 cursor-pointer"
                    alt="Delete"
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
