import React from 'react';
import Logo from "@elements/logo";
import AccountPicker from "@pickers/accountPicker";
import { Link } from "react-router-dom";
import Notifications from '@elements/notifications';
import {ActionButton} from '@components/buttons/actionButton';
import HeaderLogo from "@assets/header_logo.png";

export const Header: React.FC = () => {
  const path = window.location.pathname;

  const handleSupportClick = () => {
    // Open the email client
    window.location.href = 'mailto:support@gamelayer.co';
    setTimeout(() => {
      const button = document.querySelector('.support-button');
      if (button && path === window.location.pathname) {
        // Copy the email to the clipboard
        navigator.clipboard.writeText('support@gamelayer.co');

        // Create and display the popup
        const popup = document.createElement('div');
        popup.innerHTML = 'If your email client didn\'t open in a new tab, our support address is<br><strong>support@gamelayer.co</strong><br>It is now copied to your clipboard.';
        popup.className = 'absolute bg-white border rounded-xl mt-2 border-black w-68 p-4 z-50';
        popup.style.top = `${button.getBoundingClientRect().bottom + window.scrollY}px`;
        popup.style.left = `${button.getBoundingClientRect().left + window.scrollX}px`;

        document.body.appendChild(popup);

        // Remove the popup after 6 seconds
        setTimeout(() => {
          document.body.removeChild(popup);
        }, 6000);
      }
    }, 2000);
  };

  return (
    <header className="w.full">
      <div className="flex flex-row p-4 w.full xl:max-w-[1200px] m-auto">
        <Link to={"/home"} className="flex flex-row gap-2 items-center flex-1">
          <Logo className="max-w-24 md:max-w-48" src={HeaderLogo} alt="GameLayer" />
        </Link>
        <div className='flex flex-1 flex-row gap-4 margin-auto'>
          <ActionButton >Data</ActionButton>
          <ActionButton className='support-button' to='mailto:support@gamelayer.co' onClick={() => handleSupportClick()}>Suport</ActionButton>
          <ActionButton variant="secondary" >Upgrade</ActionButton>
        </div>
        <div className='mr-6'>
          <Notifications />
        </div>
        <div>
          <AccountPicker  />
        </div>
      </div>
    </header>
  );
}