import arrowDown from '@assets/arrow_down.svg';
interface CaretIconProps {
  className?: string;
}
const CaretIcon: React.FC<CaretIconProps> = ({ className = "min-w-6 min-h-6" }) => {
  return (
    <img src={arrowDown} alt="Caret Icon" className={className} />
  );
}

export default CaretIcon;