import { memo, useEffect, useState } from "react";
import { Entity } from "@features/common/Common";
import { Player } from "@/features/players/Player";
import { ExpandableProvider } from "@buttons/expandableButtons";
import { EntityPreview } from "@elements/entityPreview";
import Pager from "@elements/pager";
import Arrow from "@assets/left_arrow.svg";

interface EntityListProps {
  entities: Entity[] | Player[];
  entityType: string;
  listType?: string;
}

export const EntityList = memo(function EntityList({
  entities,
  entityType,
}: EntityListProps) {
  const [numberToShow, setNumberToShow] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const ArrowIcon = (
    <img
      className="rounded-full min-w-8 min-h-8"
      src={Arrow}
    />
  );
  const [heading, setHeading] = useState<JSX.Element>();
  const [content, setContent] = useState<JSX.Element[] | JSX.Element>([]);
  const listType = ['players', 'missions'].includes(entityType) ? "detailed" : "compact";

  const getEntityKey = (entity: Entity | Player): string => {
    if ("id" in entity) return entity.id;
    if ("player" in entity) return entity.player;
    return Math.random().toString();
  };

  useEffect(() => {
    setTotalPages(Math.ceil(entities?.length / numberToShow));

    const startIndex = (currentPage - 1) * numberToShow;
    const endIndex = startIndex + numberToShow;
    const pagedEntities = entities.slice(startIndex, endIndex);

    switch (listType) {
      case "detailed": {
        switch (entityType) {
          case "players": {
            const heading = (
              <thead className="border-b border-gray-300">
              <tr className="text-primary-black bg-white gap-2 mx-2 shadow-[#9E9E9E] p-4">
                <th className="flex-1"></th>
                <th className="flex-1"></th>
                <th className="flex-1 font-extrabold text-l text-black text-center">Created On</th>
                <th className="flex-1 font-extrabold text-l text-black text-center">Credits</th>
                <th className="flex-1 font-extrabold text-l text-black text-center">Points</th>
                <th className="flex-1 font-extrabold text-l text-black text-center">Level</th>
                <th className="flex-1 font-extrabold text-l text-black text-center">Team</th>
                <th className="flex-1"></th>
              </tr>
              </thead>
            );
            setHeading(heading);
            break;
          }
          case "missions": {
            const heading = (
                <thead className="border-b border-gray-300">
                  <tr className="text-primary-black bg-white gap-2 mx-2 shadow-[#9E9E9E] p-4">
                    <th className="flex-1"></th>
                    <th className="flex-1"></th>
                    <th className="flex-1 font-extrabold text-l text-black text-center">Start date</th>
                    <th className="flex-1 font-extrabold text-l text-black text-center">End date</th>
                    <th className="flex-1"></th>
                  </tr>
                </thead>
            );
            setHeading(heading);
            break
          }
        }
        const content = (
          <table>
            {heading}
            {pagedEntities.map((entity) => (
              <EntityPreview
                key={getEntityKey(entity)}
                entityType={entityType}
                entity={entity}
                className={`text-primary-black bg-white gap-2 mx-2 shadow-[#9E9E9E] p-4"
                  ${["missions", "players"].includes(entityType) ? "border-b border-gray-300" : "border border-gray-300"
                      }
                  `}
                />
            ))}
          </table>);
        setContent(content);
        break;
      }
      case "compact":
      default: {
        const content = pagedEntities.map((entity: Entity | Player) => (
            <li
            key={getEntityKey(entity)}
            className={`flex flex-row text-primary-black bg-white gap-2 mx-2 shadow-lg p-4 border border-gray-300 rounded-2xl
            ${entityType === "accounts" ? "cursor-pointer" : ""
              }`}
            >
            <EntityPreview entityType={entityType} entity={entity} />
            </li>
        ));
        setContent(content);
      }
    }


  }, [numberToShow, entities, currentPage, entityType]);

  return (
    <>
      <ExpandableProvider>
        <ul className=" flex flex-col gap-1">{content}</ul>
      </ExpandableProvider>
      <Pager
        numberToShow={numberToShow}
        totalPages={totalPages}
        totalItems={entities.length}
        currentPage={currentPage}
        onPageChange={(page: number) => setCurrentPage(page)}
        ArrowIcon={ArrowIcon}
        changeNumberToShow={(numberToShow: number) =>
          setNumberToShow(numberToShow)
        }
      />
    </>
  );
});

export default EntityList;
