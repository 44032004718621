import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Marquee from 'react-fast-marquee';
import UserIcon from '@assets/icons/users.png';
import { LogoutButton } from '@buttons/logoutButton';
import CaretIcon from './caretIcon';

const CurrentUser: React.FC = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const [currentUser, setCurrentUser] = useState<{ name?: string; picture?: string } | null>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    if (isAuthenticated && user) {
      setCurrentUser({
        name: user?.name || user?.nickname || 'John Doe',
        picture: user.picture || UserIcon,
      });
    }
  }, [isAuthenticated, user]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <div>Please log in to see user information.</div>;
  }

  return (
    <div>
      {currentUser && (
        <div className='flex p-1 gap-1 items-center w-full mt-1 mb-4'>
          <img className='rounded-full w-8 h-8' src={currentUser.picture || UserIcon} alt="User Avatar" />
          <div className='flex overflow-x-auto break-words w-32 animate-scroll'>
            {currentUser.name && currentUser.name.length > 20 ? (
              <Marquee gradient={false} speed={20} direction="left" play={true} pauseOnHover={true} loop={0}>
                {currentUser.name + '\u00A0'}
              </Marquee>
            ) : (
              <span>{currentUser.name}</span>
            )}
          </div>
          <button
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          >
            <CaretIcon />
          </button>
          <div className="relative">
            {isDropdownOpen && (
              <div className="absolute right-0 top-4 mt-2 min-w-40 bg-white border rounded-lg shadow-lg p-2">
                <LogoutButton />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CurrentUser;