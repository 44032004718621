import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  useGetSurveysQuery,
  useAddSurveyMutation,
  useUpdateSurveyMutation,
} from "@services/api/apiSlice";
import { useAccount } from "@features/accounts/useAccount";
import { BaseEntityForm } from "@components/forms/BaseEntityForm";
import RewardsPicker from "@pickers/rewardsPicker";
import { CategoryInput, TagsInput, IsShuffleInput } from "@fields/Fields";
import { SurveyQuestion } from "./surveyFields";
import { Divider } from "@fields/Display";
import { Survey, defaultSurvey, SurveyQuestionSchema } from "./Survey";

import { Button } from "@/components/ui/button";

const DEFAULT_IMAGE = import.meta.env.VITE_APP_DEFAULT_IMAGE;

const createEmptyQuestion = (): SurveyQuestionSchema => ({
  id: `question-${Date.now()}`, // temporary id until saved
  type: "single",
  text: "",
  imgUrl: DEFAULT_IMAGE,
  weight: 1,
  choices: [],
});

const SurveyPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { account } = useAccount();
  const navigate = useNavigate();

  const { data: surveys = [], isLoading } = useGetSurveysQuery({
    account: account?.account || "",
  });
  const [addNewSurvey] = useAddSurveyMutation();
  const [updateSurvey] = useUpdateSurveyMutation();

  const isNew = id === "new";

  useEffect(() => {
    if (!isNew) {
      const surveyToEdit = surveys.find((level) => level.id === id);
      if (
        !account ||
        (surveyToEdit && surveyToEdit.account !== account.account)
      ) {
        navigate("/surveys");
      }
    }
  }, [surveys, id, navigate, account, isNew]);

  const surveyToEdit = surveys.find((level) => level.id === id);
  const currentSurvey = isNew
    ? { ...defaultSurvey, account: account?.account || "" }
    : surveyToEdit || defaultSurvey;

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <BaseEntityForm<Survey>
      entityName="Survey"
      entityPath="surveys/list"
      entity={currentSurvey}
      isNew={isNew}
      defaultEntity={defaultSurvey}
      addEntity={(entity) => addNewSurvey(entity).unwrap()}
      updateEntity={(entity) => updateSurvey(entity).unwrap()}
      renderAdditionalFields={({ entity, updateField }) => (
        <>
          <div className="col-span-2 grid grid-cols-2 md:grid-cols-2 gap-4">
            <CategoryInput
              value={entity.category}
              onChange={(value) => updateField("category", value)}
            />
            <TagsInput
              value={entity.tags}
              onChange={(value) => updateField("tags", value)}
            />
          </div>
          <Divider />
          <div className="col-span-2 flex flex-col gap-4">
            {/* IsShuffleInput in its own row */}
            <div className="w-full">
              <IsShuffleInput
                value={entity.isShuffleRequired}
                onChange={(shuffle) =>
                  updateField("isShuffleRequired", shuffle)
                }
              />
            </div>

            {/* Add Question button in its own row */}
            <div className="w-full flex justify-start">
              <Button
                type="button"
                id="btn_add_survey_question"
                className="py-4 px-8"
                onClick={() => {
                  const newQuestion = createEmptyQuestion();
                  updateField("questions", [...entity.questions, newQuestion]);
                }}
              >
                Add Question
              </Button>
            </div>

            {/* Questions spanning full width */}
            <div className="w-full flex flex-col gap-4">
              {entity.questions.map((item, index) => (
                <div key={item.id} className="w-full">
                  <SurveyQuestion
                    question={item}
                    questionNumber={index + 1}
                    onChange={(updatedQuestion) => {
                      // Update the specific question in the array
                      const updatedQuestions = entity.questions.map((q) =>
                        q.id === updatedQuestion.id ? updatedQuestion : q
                      );
                      updateField("questions", updatedQuestions);
                    }}
                    onDelete={() => {
                      // Filter out the question being deleted
                      const updatedQuestions = entity.questions.filter(
                        (q) => q.id !== item.id
                      );
                      updateField("questions", updatedQuestions);
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
          <Divider />
          <div className="col-span-2">
            <RewardsPicker
              reward={entity.reward}
              className="rounded-2xl border border-primary-200 bg-white"
              onChange={(reward) => updateField("reward", reward)}
            />
          </div>
        </>
      )}
    />
  );
};

export default SurveyPage;
