import React, { useState } from 'react';
import BellIcon from '@assets/icons/notification_bell.svg';

interface Notification {
  id: number;
  message: string;
  isNew: boolean;
}

// Dummy data
const notificationsData: Notification[] = [
  { id: 1, message: 'New players added', isNew: false },
  { id: 2, message: 'New missions added', isNew: false },
  { id: 3, message: 'We love Gamification', isNew: false },
];

const Notifications: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNotifications = () => {
    setIsOpen(!isOpen);
  };

  const newNotificationsCount = notificationsData.filter(n => n.isNew).length;

  return (
    <div className="relative">
      <button className="relative flex items-center p-2 bg-neutral-100 text-white rounded-full" onClick={toggleNotifications}>
        <img src={BellIcon} className="w-6 h-6" />
        {newNotificationsCount > 0 && (
          <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full">
            {newNotificationsCount}
          </span>
        )}
      </button>
      {isOpen && (
        <div className="absolute right-0 mt-2 w-64 bg-white border border-gray-200 rounded-lg shadow-lg">
          {notificationsData.map(notification => (
            <div key={notification.id} className={`p-4 border-b last:border-0 ${notification.isNew ? 'bg-blue-50' : ''}`}>
              {notification.message}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Notifications;