import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAccount } from "@/features/accounts/useAccount";
import { usePermissions } from "@/features/permissions/usePermissions";

import { ActionButton } from "@buttons/actionButton";
import { PageLayout } from "@layout/page-layout";
import Loader from "@elements/loader";
import EntityList from "@/components/lists/entity-list";

import { Entity } from "@/features/common/Common";
import { Player } from "@/features/players/Player";

import interfaceText from "@config/interfaceText.json";

import { 
  useGetAchievementsQuery,
  useGetEventsQuery,
  useGetLeaderboardsQuery,
  useGetLevelsQuery,
  useGetMissionsQuery,
  useGetMysteryboxesQuery,
  useGetPlayersQuery,
  useGetPrizesQuery,
  useGetRafflesQuery,
  useGetStreaksQuery,
  useGetSurveysQuery,
  useGetTeamsQuery,
} from "@services/api/apiSlice";

interface InterfaceText {
  title?: string;
  description?: string;
  createButton?: string;
  editButton?: string;
  deleteButton?: string;
}

const EntityListPage: React.FC = () => {
  const { entityType } = useParams<{ entityType: string }>();
  const { account } = useAccount();
  const [entities, setEntities] = useState<Entity[] | Player[]>([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [interfaceTexts, setInterfaceTexts] = useState({} as InterfaceText);

  const { canWrite } = usePermissions();

  // Call hooks unconditionally
  const achievementsQueryResult = useGetAchievementsQuery({ account: account?.account || "" });
  const eventsQueryResult = useGetEventsQuery({ account: account?.account || "" });
  const leaderboardsQueryResult = useGetLeaderboardsQuery({ account: account?.account || "" });
  const levelsQueryResult = useGetLevelsQuery({ account: account?.account || "" });
  const missionsQueryResult = useGetMissionsQuery({ account: account?.account || "" });
  const mysteryboxesQueryResult = useGetMysteryboxesQuery({ account: account?.account || "" });
  const playersQueryResult = useGetPlayersQuery({ account: account?.account || "" });
  const prizesQueryResult = useGetPrizesQuery({ account: account?.account || "" });
  const rafflesQueryResult = useGetRafflesQuery({ account: account?.account || "" });
  const streaksQueryResult = useGetStreaksQuery({ account: account?.account || "" });
  const surveysQueryResult = useGetSurveysQuery({ account: account?.account || "" });
  const teamsQueryResult = useGetTeamsQuery({ account: account?.account || "" });

  useEffect(() => {
    let queryResult;
    switch (entityType) {
      case "achievements":
        queryResult = achievementsQueryResult;
        break;
      case "events":
        queryResult = eventsQueryResult;
        break;
      case "leaderboards":
        queryResult = leaderboardsQueryResult;
        break;
      case "levels":
        queryResult = levelsQueryResult;
        break;
      case "missions":
        queryResult = missionsQueryResult;
        break;
      case "mysteryboxes":
        queryResult = mysteryboxesQueryResult;
        break;
      case "players":
        queryResult = playersQueryResult;
        break;
      case "prizes":
        queryResult = prizesQueryResult;
        break;
      case "raffles":
        queryResult = rafflesQueryResult;
        break;
      case "streaks":
        queryResult = streaksQueryResult;
        break;
      case "surveys":
        queryResult = surveysQueryResult;
        break;
      case "teams":
        queryResult = teamsQueryResult;
        break;
      default:
        setEntities([]);
        setError("Invalid entity type");
        setIsLoading(false);
        return;
    }

    setEntities(queryResult.data || []);
    setError(queryResult.error ? queryResult.error.toString() : "");
    setIsLoading(queryResult.isLoading);

    if (entityType) {
      const selectedText = interfaceText[entityType as keyof typeof interfaceText];
      if (selectedText !== undefined) {
        setInterfaceTexts(selectedText);
      } else {
        console.warn(`Interface text for ${entityType} is undefined.`);
      }
    }
  }, [entityType, account, achievementsQueryResult, eventsQueryResult, leaderboardsQueryResult, levelsQueryResult, missionsQueryResult, mysteryboxesQueryResult, playersQueryResult, prizesQueryResult, rafflesQueryResult, streaksQueryResult, surveysQueryResult, teamsQueryResult]);

  return (
    <PageLayout>
      <div className="flex flex-col max-w-full py-6 items-center mb-6">
        <div className="flex flex-row w-full">
          <h1 className="my-auto text-2xl mr-auto font-semibold text-gray-900 capitalize">
            {interfaceTexts?.title || entityType}
          </h1>
          {canWrite() && (
            <div className="flex flex-row items-center">
              <ActionButton to={`/${entityType}/new`}>{interfaceTexts?.createButton || `Create ${entityType}`}</ActionButton>
            </div>
          )}
        </div>
        <div className="flex flex-row w-full">
        <h2 className="text-lg text-gray-700">
          {interfaceTexts?.description || ''}
        </h2>
        </div>
      </div>
      <section className="events-list">
        {isLoading ? (
          <Loader />
        ) : error ? (
          <div>Error displaying entities: {error}</div>
        ) : (
          <EntityList entities={entities} entityType={entityType || ''} />
        )}
      </section>
    </PageLayout>
  );
};

export default EntityListPage;