import React from "react";
import bwLogo from "@assets/gl_bw_logo.png";
import { ExpandableButtons } from "@buttons/expandableButtons";
import { ElementBase, ElementWithPlayer, Entity } from "@features/common/Common";
import { Player } from "@/features/players/Player";
import { Account } from "@/features/accounts/Account";
import { cn } from "@/utils/utils";

interface EntityPreviewProps {
  entityType: string;
  entity?: Entity | Player | Account;
  className?: string;
}

export const EntityPreview: React.FC<EntityPreviewProps> = ({
  entityType,
  entity,
  className
}): React.ReactNode => {

  const getEntityKey = (entity: Entity | Player): string => {
    if ("id" in entity) return entity.id;
    if ("player" in entity) return entity.player;
    return Math.random().toString();
  };
  {{ console.log(typeof entity) }}
  {{ console.log(entity) }}
  const baseUrl = `/${
    entityType +
    "/" +
    ((entity as ElementBase).id ?? (entity as ElementWithPlayer).player ?? (entity as Account).account)
  }`;

  const dateFormatter = (date: string) => {
    return new Date(date).toLocaleString("en-Us", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    }).replace(", ", " - ").replace(/\//g, ":")
  };

  const isAccount = entityType === "accounts";

  if (!entity) {
    return <p>No data available</p>;
  }

  switch (entityType) {
    case 'players':
      return (
        <tr className={cn(className, "p-4 max-h-20 text-gray-600")}>
          <td className="image w-20">
            <img
              className="w-8 h-8 md:w-16 md:h-16 p-2 rounded-full"
              src={entity.imgUrl && entity.imgUrl !== "" ? entity.imgUrl : bwLogo}
              alt={entity.name}
            />
          </td>
          <td className="flex-1 items-center">
            <div className="font-extrabold text-l text-black">
              {entity.name}
            </div>
          </td>
          <td className="flex-1 text-center">
            {entity.createdOn !== null 
              && entity.createdOn !== undefined 
              && dateFormatter(entity.createdOn)
            }
          </td>
          <td className="flex-1 text-center">
            {entity?.credits}
          </td>
          <td className="flex-1 text-center">
            {entity?.points}
          </td>
          <td className="flex-1 text-center">
            {'level' in entity && entity.level ? entity.level?.name : 'N/A'}
          </td>
          <td className="flex-1 text-center">
            {'team' in entity && entity.team }
          </td>
          <td className="w-10 ml-auto justify-items-end">
            <ExpandableButtons
              id={getEntityKey(entity)}
              links={
          isAccount
            ? [{ label: "Edit", url: `${baseUrl}` }]
            : [
                { label: "Edit", url: `${baseUrl}` },
                {
            label: "Delete",
            url: `${baseUrl}/delete`,
                },
                {
            label: "Duplicate",
            url: `${baseUrl}/clone`,
                },
              ]
              }
            />
          </td>
        </tr>
      )
    case 'missions':
      return (
        <tr className={cn(className, "p-4 max-h-20 text-gray-600")}>
          <td className="image w-20">
            <img
              className="w-8 h-8 md:w-16 md:h-16 p-2 rounded-full"
              src={entity.imgUrl && entity.imgUrl !== "" ? entity.imgUrl : bwLogo}
              alt={entity.name}
            />
          </td>
          <td className="flex flex-1 flex-col py-2 min-h-18 items-start">
            <div className="font-extrabold text-l text-black">{entity.name}</div>
            <div>{entity?.description?.length > 40 ? entity.description.substring(0, 37) + "..." : entity.description}</div>
          </td>
          <td className="flex-1 text-center">
            {entity.active !== null 
              && entity.active !== undefined 
              && dateFormatter(entity.active.from)
            }
          </td>
          <td className="flex-1 text-center">
            {entity.active !== null 
            && entity.active !== undefined 
            && dateFormatter(entity.active.to)
            }
          </td>
          <td className="w-10 ml-auto justify-items-end">
            <ExpandableButtons
              id={getEntityKey(entity)}
              links={
          isAccount
            ? [{ label: "Edit", url: `${baseUrl}` }]
            : [
                { label: "Edit", url: `${baseUrl}` },
                {
                  label: "Delete",
                  url: `${baseUrl}/delete`,
                },
                {
                  label: "Duplicate",
                  url: `${baseUrl}/clone`,
                },
              ]
              }
            />
          </td>
        </tr>
      )
    default:
      return (
        <>
          <div className="image min-w-10 md:min-w-20">
            <img
              className="w-10 h-10 md:w-20 md:h-20 rounded-full"
              src={entity.imgUrl && entity.imgUrl !== "" ? entity.imgUrl : bwLogo}
              alt={entity.name}
            />
          </div>
          <div className="bg-white">
            <h4 className="font-extrabold text-l text-black">{entity.name}</h4>
            <p>{entity.description}</p>
          </div>
          <div className="w-10 ml-auto justify-items-end">
            <ExpandableButtons
              id={getEntityKey(entity)}
              links={
                isAccount
                  ? [{ label: "Edit", url: `${baseUrl}` }]
                  : [
                      { label: "Edit", url: `${baseUrl}` },
                      {
                        label: "Delete",
                        url: `${baseUrl}/delete`,
                      },
                      {
                        label: "Duplicate",
                        url: `${baseUrl}/clone`,
                      },
                    ]
              }
            />
          </div>
        </>
      )
  }

};
